<template>
  <div class="container">
    <PublicHeader :totalName="$t('bill').title" />
    <!-- tab -->
    <van-tabs v-model="active" line-width="23" line-height="3" background="#F6F6F6" color="#C92B2E"
      title-active-color="#C92B2E" title-inactive-color="#333333" sticky offset-top="40">
      <!-- 交易记录 -->
      <van-tab :title="$t('bill').tradeLog">
        <div class="trade_tab">
          <div class="item" :class="tradeActive == 0 ? 'active' : ''" @click="tradeActive = 0">{{ $t('bill').etfTrade }}
          </div>
          <div class="item2" :class="tradeActive == 1 ? 'active' : ''" @click="tradeActive = 1">{{ $t('bill').tsTrade }}
          </div>
        </div>
        <!-- ETF交易 -->
        <div class="trade_con" v-if="tradeActive == 0">
          <van-list v-model="Balance_loading" :finished="Balance_finished" :loading-text="$t('public').loadingText"
            @load="GetBalanceChange" :immediate-check="false">
            <div class="item" v-for="(item, index) in Balance_List" :key="`balance_etf${index}`">
              <div class="time">{{ item.CreateTime | getLocalTime }} <span :class="item.Amount > 0 ? 'red' : 'green'">
                  {{ LegalChangeType |
                    showName(item.LegalChangeType) }}</span>
              </div>
              <div class="name">{{ item.ProductName }}</div>
              <div class="txt">{{ $t('public').orderNo }}：{{ item.OrderNo }}</div>
              <div class="txt">{{ $t('public').amount }}：{{ item.Amount }}（USDT）</div>
            </div>
          </van-list>
        </div>
        <!-- 托管交易 -->
        <div class="trade_con" v-if="tradeActive == 1">
          <van-list v-model="Balance_loading" :finished="Balance_finished" :loading-text="$t('public').loadingText"
            @load="GetBalanceChange" :immediate-check="false">
            <div class="item" v-for="(item, index) in Balance_List" :key="`balance_etf${index}`">
              <div class="time">{{ item.CreateTime | getLocalTime }} <span :class="item.Amount > 0 ? 'red' : 'green'">
                  {{
                    LegalChangeType |
                    showName(item.LegalChangeType) }}</span>
              </div>
              <div class="name">{{ item.ProductName }}</div>
              <div class="txt">{{ $t('public').orderNo }}：{{ item.OrderNo }}</div>
              <div class="txt">{{ $t('public').amount }}：{{ item.Amount }}（USDT）</div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <!--充币记录  -->
      <van-tab :title="$t('bill').rechargeLog">
        <div class="trade_con">
          <van-list v-model="Recharge_loading" :finished="Recharge_finished" :loading-text="$t('public').loadingText"
            @load="GetRechargeLog">
            <div class="item" v-for="(item, index) in Recharge_List" :key="`recharge${index}`">
              <div class="time">{{ item.CreateTime | getLocalTime }} <span
                  :class="item.AuditStatus == 'Success' ? 'green' : item.AuditStatus == 'Fail' ? 'red' : ''"> {{
                    AuditStatus |
                    showName(item.AuditStatus)
                  }}</span></div>
              <div class="name">{{ item.WalletNetworkType }}</div>
              <div class="txt">{{ $t('bill').rechargeAmount }}：{{ item.Amount }}（{{ item.Currency }}）</div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 提币记录 -->
      <van-tab :title="$t('bill').withLog">
        <div class="trade_con">
          <van-list v-model="With_loading" :finished="With_finished" :loading-text="$t('public').loadingText"
            @load="GetWithdrawalRecord">
            <div class="item" v-for="(item, index) in With_List" :key="`with${index}`">
              <div class="time">{{ item.CreateTime | getLocalTime }} <span
                  :class="item.AuditStatus == 'Success' ? 'green' : item.AuditStatus == 'Fail' ? 'red' : ''"> {{
                    AuditStatus |
                    showName(item.AuditStatus)
                  }}</span></div>
              <div class="name">{{ item.WalletNetworkType }}</div>
              <div class="txt">{{ $t('bill').withAmount }}：{{ item.Amount }}（{{ item.Currency }}）</div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      // 资金明细类型
      LegalChangeType: [
        {
          Id: 'TsOrder',
          Name: this.$t('bill').TsOrder
        },
        {
          Id: 'TsSettle',
          Name: this.$t('bill').TsSettle
        },
        {
          Id: 'TsCommission',
          Name: this.$t('bill').TsCommission
        },
        {
          Id: 'TsStop',
          Name: this.$t('bill').TsStop
        },
        {
          Id: 'TsEndReturn',
          Name: this.$t('bill').TsEndReturn
        },
        {
          Id: 'EtfOrder',
          Name: this.$t('bill').EtfOrder
        },
        {
          Id: 'EtfSettle',
          Name: this.$t('bill').EtfSettle
        },
        {
          Id: 'EtfCancel',
          Name: this.$t('bill').EtfCancel
        }
      ],
      isRun: false,
      active: 0,
      tradeActive: 0,
      // 交易记录余额明细
      Balance_List: [],
      Balance_loading: false,
      Balance_finished: false,
      Balance_parms: {
        page: 1,
        size: 10,
        Type: 'ETF',
      },
      // 审核状态
      AuditStatus: [
        {
          Id: 'Wait',
          Name: this.$t('bill').Wait
        },
        {
          Id: 'Success',
          Name: this.$t('bill').Success
        },
        {
          Id: 'Fail',
          Name: this.$t('bill').Fail
        },
      ],
      // 充币记录
      Recharge_List: [],
      Recharge_loading: false,
      Recharge_finished: false,
      Recharge_parms: {
        page: 1,
        size: 10,
      },
      // 提币记录
      With_List: [],
      With_loading: false,
      With_finished: false,
      With_parms: {
        page: 1,
        size: 10,
      },
    }
  },
  watch: {
    tradeActive() {
      this.tradeActivechangeTab()
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.GetBalanceChange()
  },
  methods: {
    // 交易记录切换
    tradeActivechangeTab() {
      if (this.tradeActive == 0)
        this.Balance_parms.Type = 'ETF'
      if (this.tradeActive == 1)
        this.Balance_parms.Type = 'TS'
      this.Balance_List = []
      this.Balance_parms.page = 1
      this.Balance_finished = false;
      this.GetBalanceChange()
    },
    // 交易记录余额明细
    async GetBalanceChange() {
      if (this.isRun) return
      this.isRun = true
      const res = await userApi.GetBalanceChange(this.Balance_parms)
      this.Balance_List.push(...res.Data);
      this.isRun = false
      this.Balance_parms.page++
      // 加载状态结束
      this.Balance_loading = false;
      // 数据全部加载完成
      if (this.Balance_List.length >= res.TotalCount) {
        this.Balance_finished = true;
      }
    },
    // 充币记录
    async GetRechargeLog() {
      const res = await userApi.GetRechargeLog(this.Recharge_parms)
      this.Recharge_List.push(...res.Data);
      this.Recharge_parms.page++
      // 加载状态结束
      this.Recharge_loading = false;
      // 数据全部加载完成
      if (this.Recharge_List.length >= res.TotalCount) {
        this.Recharge_finished = true;
      }
    },
    // 提币记录
    async GetWithdrawalRecord() {
      const res = await userApi.GetWithdrawalRecord(this.With_parms)
      this.With_List.push(...res.Data);
      this.With_parms.page++
      // 加载状态结束
      this.With_loading = false;
      // 数据全部加载完成
      if (this.With_List.length >= res.TotalCount) {
        this.With_finished = true;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.red {
  color: #C92B2E !important;
}

.green {
  color: #02B270 !important;
}

.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px 10px 15px;
  background: #F6F6F6;

  ::v-deep .van-tab {
    font-size: 15px;
  }

  ::v-deep .van-tab--active {
    font-weight: bold;
  }

  .trade_tab {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 27px;
      background: #FFFFFF;
      border-radius: 2px 0px 0px 2px;
      font-size: 13px;
      color: #666666;
    }

    .item2 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 27px;
      background: #FFFFFF;
      border-radius: 0px 2px 2px 0px;
      font-size: 13px;
      color: #666666;
    }

    .active {
      font-size: 13px;
      color: #FFFFFF;
      background: #C92B2E;
    }
  }

  .trade_con {
    margin-top: 20px;

    .item {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 10px;

      .time {
        padding: 10px;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        color: #999999;

        span {
          font-size: 14px;
        }
      }

      .name {
        padding: 10px;
        font-weight: bold;
        font-size: 15px;
        color: #333333;
      }

      .txt {
        padding: 0 10px 10px 10px;
        font-size: 14px;
        color: #333333;
      }
    }

  }


}
</style>
